import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useApollo } from './ApolloProvider';
import SplashScreen from './SplashScreen';
import { CustomerFileType } from '../../types/customerFile';
import { PermissionEnum } from '../../types/permission';
import { FETCH_CUSTOMER_FILE_BY_CODE, FetchCustomerFileByCodeType } from '../api/customerFile';
import { FETCH_MODULES, FetchModulesType } from '../api/module';
import { FETCH_SESSION, FetchSessionType } from '../api/session';
import { SessionPermissionType, SessionUserType } from '../types/session';
import { UserType, UserTypeEnum } from '../types/user';
import useLocalStorage from '../utils/useLocalStorage';

export type ImpersonatedUserType = Pick<UserType, 'id' | 'email' | 'lastName' | 'firstName'>;

export type SessionContextType = {
	user: SessionUserType;
	customerFile?: CustomerFileType;
	permissions: SessionPermissionType[];
	impersonatedUser?: ImpersonatedUserType;
	checkPermission: (code: PermissionEnum) => boolean;
	changeCustomerFile: (customerFile: CustomerFileType) => Promise<void>;
	refetch: () => Promise<void>;
	impersonate: (user?: ImpersonatedUserType) => Promise<void>;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider = ({ children }: { children: JSX.Element }) => {
	const {
		customerFileId: apolloCustomerFileId,
		setCustomerFileId: setApolloCustomerFileId,
		setUserId: setApolloUserId,
		refetchActiveQueries,
		apolloClient,
	} = useApollo();

	const router = useRouter();
	const { replace, push, pathname, query } = router;
	const { dossier: queryCustomerFileCode } = query || {};

	const routerRef = useRef(router);
	routerRef.current = router;

	const [localStorageSessionCustomerFile, setLocalStorageSessionCustomerFile] = useLocalStorage<
		CustomerFileType | undefined
	>('sessionCustomerFile', undefined);

	const [isImpersonateLoading, setImpersonateLoading] = useState(false);
	const [impersonatedUser, setImpersonatedUser] = useState<ImpersonatedUserType>();
	const [userSessionCustomerFile, setUserSessionCustomerFile] = useState<CustomerFileType | undefined>();

	const { loading } = useQuery<FetchModulesType>(FETCH_MODULES, {
		onCompleted: ({ modules: fetchedModules }) => {
			if (fetchedModules) {
				const isModuleAvailable = fetchedModules.some(({ code }) => pathname.includes(code));

				if (pathname !== '/' && !isModuleAvailable) {
					push('/error/403').catch((e) => {
						throw e;
					});
				}
			}
		},
		onError: () => {
			push('/error/403').catch((e) => {
				throw e;
			});
		},
		skip: !apolloCustomerFileId,
	});

	const { data: sessionData, refetch } = useQuery<FetchSessionType>(FETCH_SESSION, {
		onCompleted: ({ session: { customerFile } }) => {
			if (customerFile === null) {
				push('/error/emptyCustomerFiles').catch((e) => {
					throw e;
				});
			} else {
				if (!queryCustomerFileCode && !localStorageSessionCustomerFile) {
					setLocalStorageSessionCustomerFile(customerFile);
				}

				setUserSessionCustomerFile(customerFile);
			}
		},
	});
	const { session } = sessionData ?? {};

	const { data: customerFileData } = useQuery<FetchCustomerFileByCodeType>(FETCH_CUSTOMER_FILE_BY_CODE, {
		onCompleted: ({ customerFileByCode }) => setLocalStorageSessionCustomerFile(customerFileByCode),
		variables: { code: queryCustomerFileCode },
		skip: !queryCustomerFileCode,
	});
	const { customerFileByCode: customerFile } = customerFileData || {};

	const sessionCustomerFile = useMemo(() => {
		if (queryCustomerFileCode && customerFile) {
			return { ...customerFile, minimumEntryDate: new Date(customerFile.minimumEntryDate) };
		}

		if (localStorageSessionCustomerFile) {
			return {
				...localStorageSessionCustomerFile,
				minimumEntryDate: new Date(localStorageSessionCustomerFile.minimumEntryDate),
			};
		}

		return userSessionCustomerFile
			? {
					...userSessionCustomerFile,
					minimumEntryDate: new Date(userSessionCustomerFile.minimumEntryDate),
			  }
			: undefined;
	}, [customerFile, localStorageSessionCustomerFile, queryCustomerFileCode, userSessionCustomerFile]);

	const contextValue = useMemo(
		() =>
			session && {
				user: session.user,
				customerFile: sessionCustomerFile,
				permissions: session.permissions,
				impersonatedUser,
				checkPermission: (code: PermissionEnum) =>
					session.permissions.some((permission) => code === permission.code && permission.value),
				changeCustomerFile: async (newCustomerFile?: CustomerFileType) => {
					await Promise.resolve(setLocalStorageSessionCustomerFile(newCustomerFile));
					await Promise.resolve(setApolloCustomerFileId(newCustomerFile?.id));
					await push({ pathname: '/', query: { dossier: newCustomerFile?.code } });
					await refetchActiveQueries();
				},
				refetch: async () => {
					await refetch();
				},
				impersonate: async (user?: ImpersonatedUserType) => {
					try {
						setImpersonateLoading(true);
						setUserSessionCustomerFile(undefined);
						setLocalStorageSessionCustomerFile(undefined);
						await routerRef.current.push('/');
						const { id } = user ?? {};
						setImpersonatedUser(user);
						setApolloCustomerFileId(undefined);
						setApolloUserId(id);
						await refetch({ notifyOnNetworkStatusChange: true });
					} finally {
						setImpersonateLoading(false);
					}
					await apolloClient.refetchQueries({ include: 'active' });
				},
			},
		[
			apolloClient,
			impersonatedUser,
			push,
			refetch,
			refetchActiveQueries,
			session,
			sessionCustomerFile,
			setApolloCustomerFileId,
			setApolloUserId,
			setLocalStorageSessionCustomerFile,
		],
	);

	useEffect(() => {
		setApolloCustomerFileId(sessionCustomerFile?.id);

		if (!pathname.includes('error') && !queryCustomerFileCode && sessionCustomerFile) {
			replace({ query: { dossier: sessionCustomerFile.code } }).catch((error) => {
				throw error;
			});
		}
	}, [pathname, queryCustomerFileCode, replace, sessionCustomerFile, setApolloCustomerFileId]);

	if (pathname.startsWith('/error')) {
		return children;
	}

	if (isImpersonateLoading) {
		return <SplashScreen description="Chargement de l'utilisateur..." />;
	}

	if (!contextValue || loading) {
		return <SplashScreen description="Chargement de la session..." />;
	}

	if (contextValue.user.type === UserTypeEnum.EXTERNAL && !contextValue.customerFile) {
		push('/error/403').catch((e) => {
			throw e;
		});
		return null;
	}

	return contextValue ? <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider> : null;
};

export const useSession = (): SessionContextType => {
	const context = useContext(SessionContext);
	if (context === undefined) {
		throw new Error('SessionContext should be used within a Provider');
	}

	return context;
};
