import { useMutation, useQuery } from '@apollo/client';
import { Autocomplete, CurrencyField, DatePicker, Dialog, DialogProps, TextField } from '@elipssolution/harfang';
import { CircularProgress, Typography, styled } from '@mui/material';
import { isAfter, isWithinInterval } from 'date-fns';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import useAccountsByCheckAccountDataSource from './hooks/useAccountsByCheckAccountDataSource';
import useCheckAccountTypesDataSource from './hooks/useCheckAccountTypesDataSource';
import useCheckConfigurations from './hooks/useCheckConfigurations';
import { useSession } from '../../../../src/components/SessionProvider';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { findMinMaxDates } from '../../../../utils/fiscalYears';
import {
	FETCH_NEXT_CHECK_DOCUMENT_NUMBER_VALUE,
	FetchNextCheckDocumentNumberValueType,
	CreateCheckType,
	CREATE_CHECK,
	UpdateCheckType,
	UPDATE_CHECK,
} from '../../api/check';
import useBank from '../../hooks/useBank';
import useBankAccountDataSource from '../../hooks/useBankAccountDataSource';
import useUnpaginatedFiscalYears from '../../hooks/useUnpaginatedFiscalYears';
import { AccountType } from '../../types/account';
import { BankType } from '../../types/bank';
import { CheckType } from '../../types/check';
import { CheckAccountTypeType } from '../../types/checkAccountType';
import { getConnectorMaxNameLength } from '../../utils/connector';

const FormWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(2),
}));

const FormRow = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(2),

	'& > *': {
		flex: 1,
	},
}));

const LoadingWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(2),
}));

type FormType = {
	account: CheckType['account'] | null;
	amount: string;
	checkAccountType: CheckAccountTypeType | null;
	documentDate?: Date;
	documentNumber: CheckType['documentNumber'];
	name: CheckType['name'];
};

const formDefaultValues = {
	account: null,
	amount: '',
	checkAccountType: null,
	documentDate: undefined,
	documentNumber: '',
	name: '',
};

type CheckDialogProps = {
	bankId?: BankType['id'];
	check?: CheckType;
	onCheckEdition: () => void;
	onClose: () => void;
	open: boolean;
	readOnly: boolean;
};

const CheckDialog = ({ bankId: selectedBankId, check, onCheckEdition, onClose, open, readOnly }: CheckDialogProps) => {
	const { bank: checkBank, id: checkId, name } = check ?? {};
	const bankId = checkBank?.id ?? selectedBankId;

	const { customerFile } = useSession();
	const { connector, minimumEntryDate } = customerFile ?? {};

	const nameMaxLength = useMemo(() => (connector ? getConnectorMaxNameLength(connector) ?? 999 : 999), [connector]);

	const [isCheckEditSuccess, setIsCheckEditSuccess] = useState(false);
	const [editCheckError, setEditCheckError] = useState<string>();

	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		reset,
		setError: setFormError,
		setValue,
		watch,
		clearErrors,
	} = useForm<FormType>({
		defaultValues: formDefaultValues,
		mode: 'onBlur',
	});

	const formCheckDate = watch('documentDate');
	const formCheckAccountType = watch('checkAccountType');
	const formDocumentNumber = watch('documentNumber');

	const {
		called: isFetchFiscalYearsCalled,
		fiscalYears,
		loading: isFiscalYearsFetchLoading,
	} = useUnpaginatedFiscalYears({ skip: !!check || !!selectedBankId });

	const { bank, loading: isBankFetchLoading, error: bankFetchError } = useBank({ bankId });

	const { error: checkConfigurationsFetchError, loading: isConfigurationsFetchLoading } = useCheckConfigurations({
		skip: !!check || !bankId,
	});

	const fetchError = useMemo(
		() => bankFetchError || checkConfigurationsFetchError,
		[bankFetchError, checkConfigurationsFetchError],
	);

	const isMandatoryDataLoading = useMemo(
		() => isBankFetchLoading || isConfigurationsFetchLoading || isFiscalYearsFetchLoading,
		[isBankFetchLoading, isConfigurationsFetchLoading, isFiscalYearsFetchLoading],
	);

	useQuery<FetchNextCheckDocumentNumberValueType>(FETCH_NEXT_CHECK_DOCUMENT_NUMBER_VALUE, {
		onCompleted: ({ quickentry_nextCheckDocumentNumberValue }) =>
			setValue('documentNumber', quickentry_nextCheckDocumentNumberValue),
		skip: !bankId,
		variables: {
			id: bankId,
		},
	});

	const { dataSource: bankAccountDataSource } = useBankAccountDataSource({ bank });

	const { dataSource: checkAccountTypesDataSource } = useCheckAccountTypesDataSource();

	const { dataSource: accountsDataSource } = useAccountsByCheckAccountDataSource({
		checkAccountType: formCheckAccountType,
	});

	const handleClose = useCallback(() => {
		setIsCheckEditSuccess(false);
		setEditCheckError(undefined);

		onClose();
		reset(formDefaultValues);
	}, [onClose, reset]);

	const [createCheck, { loading: isCreateCheckLoading }] = useMutation<CreateCheckType>(CREATE_CHECK, {
		onCompleted: () => {
			setIsCheckEditSuccess(true);
			setTimeout(() => {
				setIsCheckEditSuccess(false);

				handleClose();

				onCheckEdition();
			}, 3000);
		},
		onError: (mutationError) =>
			setEditCheckError(
				generateErrorInformations({
					error: mutationError,
					resource: 'quickentry_createCheck',
				}).message,
			),
	});

	const [updateCheck, { loading: isUpdateCheckLoading }] = useMutation<UpdateCheckType>(UPDATE_CHECK, {
		onCompleted: () => {
			setIsCheckEditSuccess(true);
			setTimeout(() => {
				setIsCheckEditSuccess(false);

				handleClose();

				onCheckEdition();
			}, 1500);
		},
		onError: (mutationError) =>
			setEditCheckError(
				generateErrorInformations({
					error: mutationError,
					resource: 'quickentry_updateCheck',
				}).message,
			),
	});

	const isMutationButtonDisabled = useMemo(() => !isDirty || !isValid, [isDirty, isValid]);
	const openedFiscalYearsBoundaries = useMemo(() => findMinMaxDates(fiscalYears), [fiscalYears]);
	const { maxDate: fiscalYearMaxDate, minDate: fiscalYearMinDate } = openedFiscalYearsBoundaries ?? {};

	const minimumDate = useMemo(() => {
		if (
			(formCheckAccountType?.accountPrefix?.startsWith('6') || formCheckAccountType?.accountPrefix?.startsWith('7')) &&
			minimumEntryDate
		) {
			if (!fiscalYearMinDate) {
				return minimumEntryDate;
			}

			return isAfter(fiscalYearMinDate, minimumEntryDate) ? fiscalYearMinDate : minimumEntryDate;
		}

		return fiscalYearMinDate;
	}, [fiscalYearMinDate, formCheckAccountType, minimumEntryDate]);

	const onSubmit = useCallback(
		({ account, amount, checkAccountType, documentDate, documentNumber, name: formName }: FormType) => {
			if (!bank || account === null || checkAccountType === null || !documentDate) return null;

			const checkInput = {
				accountId: account.id,
				bankId: bank.id,
				checkAccountTypeId: checkAccountType.id,
				name: formName,
				amount,
				documentDate,
				documentNumber,
			};

			if (checkId) {
				return updateCheck({
					variables: {
						updateCheckInput: {
							id: checkId,
							...checkInput,
						},
					},
				});
			}

			return createCheck({
				variables: {
					createCheckInput: checkInput,
				},
			});
		},
		[createCheck, updateCheck, bank, checkId],
	);

	const submitButtonLabel = useMemo(() => {
		if (check) return isCheckEditSuccess ? 'Saisie modifiée' : 'Modifier la saisie';
		return isCheckEditSuccess ? 'Chèque saisi' : 'Saisir';
	}, [check, isCheckEditSuccess]);

	const actionsDialog = useMemo(
		() =>
			[
				{
					label: readOnly ? 'Fermer' : 'Annuler',
					loading: isCreateCheckLoading || isUpdateCheckLoading,
					onClick: handleClose,
				},
				...(!readOnly
					? [
							{
								disabled: isMutationButtonDisabled,
								error: !!editCheckError,
								label: submitButtonLabel,
								loading: isCreateCheckLoading || isUpdateCheckLoading,
								onClick: handleSubmit(onSubmit),
								success: isCheckEditSuccess,
								variant: 'contained',
							},
					  ]
					: []),
			] as DialogProps['actionsDialog'],
		[
			readOnly,
			isCreateCheckLoading,
			isUpdateCheckLoading,
			handleClose,
			isMutationButtonDisabled,
			editCheckError,
			submitButtonLabel,
			handleSubmit,
			onSubmit,
			isCheckEditSuccess,
		],
	);

	const validateDocumentDate = (value?: Date): boolean => {
		if (!value || !isFetchFiscalYearsCalled) {
			clearErrors('documentDate');
			return true;
		}

		if (minimumDate && fiscalYearMaxDate) {
			const isDateInBoundaries = isWithinInterval(value, { start: minimumDate, end: fiscalYearMaxDate });

			if (!isDateInBoundaries) {
				return false;
			}
		}

		const fiscalYear = fiscalYears?.find(({ startDate, endDate }) =>
			isWithinInterval(value, { start: startDate, end: endDate }),
		);

		if (!fiscalYear) {
			setFormError('documentDate', { message: "Aucun exercice en cours n'inclut cette date" });
			return false;
		}
		if (fiscalYear.isClosed) {
			setFormError('documentDate', { message: "L'exercice est clôturé pour cette date" });
			return false;
		}
		clearErrors('documentDate');
		return true;
	};

	useEffect(() => {
		if (check) {
			const { amount, ...rest } = check ?? {};

			reset({
				...rest,
				amount: numeral(amount).format('0,00'),
			});
		}
	}, [reset, check]);

	useEffect(() => {
		if (minimumDate && formCheckDate && isAfter(minimumDate, formCheckDate)) {
			setFormError('documentDate', { message: 'La date du chèque ne peut pas précéder la date minimale de saisie.' });
		}
	}, [setFormError, formCheckDate, minimumDate]);

	// Initialize the documentDate value
	useEffect(() => {
		const todayDate = new Date();

		if (dirtyFields.documentDate) return;

		if (!minimumDate || isAfter(todayDate, minimumDate)) {
			setValue('documentDate', todayDate);
		} else {
			setValue('documentDate', minimumDate);
		}
	}, [setValue, dirtyFields, minimumDate]);

	return (
		<Dialog
			actionsDialog={actionsDialog}
			error={fetchError}
			open={open}
			onClose={handleClose}
			title={name ?? 'Saisir un chèque'}
			maxWidth="sm"
			fullWidth
		>
			{isMandatoryDataLoading ? (
				<LoadingWrapper>
					<CircularProgress size={36} color="inherit" />
					<Typography>Chargement en cours...</Typography>
				</LoadingWrapper>
			) : (
				<FormWrapper>
					<FormRow>
						<Autocomplete<Required<BankType>['account']>
							dataSource={bankAccountDataSource}
							getOptionLabel={({ code, name: accountName }) => `${code} - ${accountName}`}
							label="Banque"
							value={bank?.account}
							readOnly={readOnly || !!checkId}
						/>
					</FormRow>

					<FormRow>
						<Controller
							control={control}
							render={({ field, fieldState: { error: fieldError } }) => (
								<DatePicker
									{...field}
									label="Date du chèque"
									readOnly={readOnly}
									helperText={fieldError?.message}
									invalid={!!fieldError}
									min={minimumDate}
									max={fiscalYearMaxDate}
									disableClearable
								/>
							)}
							name="documentDate"
							rules={{
								required: 'Champ requis',
								validate: validateDocumentDate,
							}}
						/>
						<Controller
							control={control}
							render={({ field: { onChange, ...field }, fieldState: { error: fieldError } }) => {
								const handleChange = (value?: string) => {
									if (value && value.length > 7) {
										setFormError('documentNumber', { message: 'Maximum 7 caractères.' });
									} else {
										onChange(value);
									}
								};

								return (
									<TextField
										{...field}
										label="Numéro du chèque"
										invalid={!!fieldError}
										helperText={fieldError?.message}
										onChange={handleChange}
										readOnly={readOnly}
									/>
								);
							}}
							name="documentNumber"
							rules={{
								required: 'Champ requis',
								maxLength: { value: 7, message: 'Maximum 7 caractères.' },
							}}
						/>
					</FormRow>
					<FormRow>
						<Controller
							control={control}
							render={({ field: { value, ...field } }) => (
								<Autocomplete<CheckAccountTypeType>
									{...field}
									dataSource={checkAccountTypesDataSource}
									getOptionLabel={({ name: checkAccountTypeName }) => checkAccountTypeName}
									label="Type de compte"
									readOnly={readOnly}
									value={value ?? undefined}
									disableClearable
								/>
							)}
							name="checkAccountType"
							rules={{ required: 'Champ requis' }}
						/>
						<Controller
							control={control}
							render={({ field: { onChange, value, ...field } }) => {
								const handleChange = (newValue?: AccountType) => {
									onChange(newValue);
									!!newValue?.name &&
										setValue('name', `${newValue.name}${formDocumentNumber ? ` N°${formDocumentNumber}` : ''}`, {
											shouldDirty: true,
											shouldValidate: true,
										});
								};

								return (
									<Autocomplete<AccountType>
										{...field}
										dataSource={accountsDataSource}
										disabled={!formCheckAccountType}
										getOptionLabel={({ code, name: accountName }) => `${code} - ${accountName}`}
										label="Compte"
										onChange={handleChange}
										optionWidth={300}
										readOnly={readOnly}
										value={value ?? undefined}
										disableClearable
									/>
								);
							}}
							name="account"
							rules={{ required: 'Champ requis' }}
						/>
					</FormRow>
					<FormRow>
						<Controller
							control={control}
							render={({ field: { onChange, ...field }, fieldState: { error: fieldError } }) => {
								const handleChange = (value?: string) => {
									if (value && value.length > nameMaxLength) {
										setFormError('name', { message: `Maximum ${nameMaxLength} caractères.` });
									} else {
										clearErrors('name');
										onChange(value);
									}
								};

								return (
									<TextField
										{...field}
										label="Libellé"
										invalid={!!fieldError}
										helperText={fieldError?.message}
										onChange={handleChange}
										readOnly={readOnly}
									/>
								);
							}}
							name="name"
							rules={{
								required: 'Champ requis',
								maxLength: { message: `Maximum ${nameMaxLength} caractères.`, value: nameMaxLength },
							}}
						/>
						<Controller
							control={control}
							render={({ field }) => (
								<CurrencyField
									{...field}
									label="Montant"
									readOnly={readOnly}
									sx={{ ...(readOnly && { marginLeft: 24 }) }}
								/>
							)}
							name="amount"
							rules={{ required: 'Champ requis' }}
						/>
					</FormRow>
				</FormWrapper>
			)}
		</Dialog>
	);
};

export default CheckDialog;
